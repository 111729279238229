import update from "immutability-helper"
import { DEACTIVE_MERCHANT_FAILED, DEACTIVE_MERCHANT_START, DEACTIVE_MERCHANT_SUCCESS, EDIT_SETTING_MERCHANT_PREVIEW_FAILED, EDIT_SETTING_MERCHANT_PREVIEW_START, EDIT_SETTING_MERCHANT_PREVIEW_SUCCESS, GET_IP_WHITELIST_FAILED, GET_IP_WHITELIST_START, GET_IP_WHITELIST_SUCCESS, GET_SETTING_MERCHANT_PREVIEW_FAILED, GET_SETTING_MERCHANT_PREVIEW_START, GET_SETTING_MERCHANT_PREVIEW_SUCCESS, GET_SETTING_MESSAGE_FAILED, GET_SETTING_MESSAGE_START, GET_SETTING_MESSAGE_SUCCESS, GET_SETTING_SECURITY_FAILED, GET_SETTING_SECURITY_START, GET_SETTING_SECURITY_SUCCESS, POST_GENERATE_KEY_FAILED, POST_GENERATE_KEY_START, POST_GENERATE_KEY_SUCCESS, POST_GENERATE_SECRET_FAILED, POST_GENERATE_SECRET_START, POST_GENERATE_SECRET_SUCCESS, PUT_IP_WHITELIST_FAILED, PUT_IP_WHITELIST_START, PUT_IP_WHITELIST_SUCCESS, PUT_SETTING_MESSAGE_FAILED, PUT_SETTING_MESSAGE_START, PUT_SETTING_MESSAGE_SUCCESS, PUT_SETTING_SECURITY_FAILED, PUT_SETTING_SECURITY_START, PUT_SETTING_SECURITY_SUCCESS } from "../actions/actionType";

const initialState = {
    data: [],
    message: "",
    isLoading: false,
    Security: {
        data: {
            code: '',
            api_key: '',
            api_secret: '',
            api_public: ''
        },
        isLoading: false,
        message: "",
    },
    Whitelist: {
        data: {},
        isLoading: false,
        message: ""
    },
    Messaging : {
        data: {},
        isLoading: false,
        message: ""
    }
}

function settingReducer(state = initialState, action) {
    switch (action.type) {
        case GET_SETTING_MERCHANT_PREVIEW_START:
            return update(state, {
                isLoading: { $set: true },
                message: { $set: "" }
            })
        case GET_SETTING_MERCHANT_PREVIEW_SUCCESS:
            return update(state, {
                isLoading: { $set: false },
                data: { $set: action.payload }
            })
        case GET_SETTING_MERCHANT_PREVIEW_FAILED:
            return update(state, {
                isLoading: { $set: false },
                message: { $set: action.payload }
            })
        case EDIT_SETTING_MERCHANT_PREVIEW_START:
            return update(state, {
                isLoading: { $set: true },
                message: { $set: '' }
            })
        case EDIT_SETTING_MERCHANT_PREVIEW_SUCCESS:
            return update(state, {
                isLoading: { $set: false },
                message: { $set: action.payload }
            })
        case EDIT_SETTING_MERCHANT_PREVIEW_FAILED:
            return update(state, {
                isLoading: { $set: false },
                message: { $set: action.payload }
            })
        case DEACTIVE_MERCHANT_START:
            return update(state, {
                isLoading: { $set: true },
                message: { $set: '' }
            })
        case DEACTIVE_MERCHANT_SUCCESS:
            return update(state, {
                isLoading: { $set: false },
                message: { $set: action.payload }
            })
        case DEACTIVE_MERCHANT_FAILED:
            return update(state, {
                isLoading: { $set: false },
                message: { $set: action.payload }
            })
        case GET_SETTING_SECURITY_START:
            return update(state, {
                Security: {
                    isLoading: { $set: true },
                    message: { $set: '' }
                }
            })
        case GET_SETTING_SECURITY_SUCCESS:
            return update(state, {
                Security: {
                    isLoading: { $set: false },
                    data: { $set: action.payload }
                }
            })
        case GET_SETTING_SECURITY_FAILED:
            return update(state, {
                Security: {
                    isLoading: { $set: false },
                    message: { $set: action.payload }
                }
            })
        case POST_GENERATE_SECRET_START:
            return update(state, {
                Security: {
                    message: { $set: '' }
                }
            })
        case POST_GENERATE_SECRET_SUCCESS:
            return update(state, {
                Security: {
                    data: {
                        api_secret: { $set: action.payload }
                    }
                }
            })
        case POST_GENERATE_SECRET_FAILED:
            return update(state, {
                Security: {
                    message: { $set: action.payload }
                }
            })
        case POST_GENERATE_KEY_START:
            return update(state, {
                Security: {
                    message: { $set: '' }
                }
            })
        case POST_GENERATE_KEY_SUCCESS:
            return update(state, {
                Security: {
                    data: {
                        api_key: { $set: action.payload }
                    }
                }
            })
        case POST_GENERATE_KEY_FAILED:
            return update(state, {
                Security: {
                    message: { $set: action.payload }
                }
            })
        case PUT_SETTING_SECURITY_START:
            return update(state, {
                Security: {
                    message: { $set: '' }
                }
            })
        case PUT_SETTING_SECURITY_SUCCESS:
            return update(state, {
                Security: {
                    message: { $set: action.payload }
                }
            })
        case PUT_SETTING_SECURITY_FAILED:
            return update(state, {
                Security: {
                    message: { $set: action.payload }
                }
            })
        case GET_IP_WHITELIST_START:
            return update(state, {
                Whitelist: {
                    isLoading: { $set: true },
                    message: { $set: '' }
                }
            })
        case GET_IP_WHITELIST_SUCCESS:
            return update(state, {
                Whitelist: {
                    isLoading: { $set: false },
                    data: { $set: action.payload }
                }
            })
        case GET_IP_WHITELIST_FAILED:
            return update(state, {
                Whitelist: {
                    isLoading: { $set: false },
                    message: { $set: action.payload }
                }
            })
        case PUT_IP_WHITELIST_START:
            return update(state, {
                Whitelist: {
                    message: { $set: '' }
                }
            })
        case PUT_IP_WHITELIST_SUCCESS:
            return update(state, {
                Whitelist: {
                    message: { $set: action.payload }
                }
            })
        case PUT_IP_WHITELIST_FAILED:
            return update(state, {
                Whitelist: {
                    message: { $set: action.payload }
                }
            })
        case GET_SETTING_MESSAGE_START:
            return update(state, {
                Messaging : {
                    isLoading : {$set : true}
                }
            })
        case GET_SETTING_MESSAGE_SUCCESS:
            return update(state, {
                Messaging : {
                    isLoading : {$set : false},
                    data : {$set : action.payload}
                }
            })
        case GET_SETTING_MESSAGE_FAILED:
            return update(state, {
                Messaging : {
                    isLoading : {$set : false},
                    message : {$set : action.payload}
                }
            })
        case PUT_SETTING_MESSAGE_START:
            return update(state, {
                Messaging : {
                    message : {$set : ''}
                }
            })
        case PUT_SETTING_MESSAGE_SUCCESS:
            return update(state, {
                Messaging : {
                    message : {$set : action.payload}
                }
            })
        case PUT_SETTING_MESSAGE_FAILED:
            return update(state, {
                Messaging : {
                    message : {$set : action.payload}
                }
            })
        default:
            return state
    }
}

export default settingReducer
