import { getCookie } from "../index"


export function permissionFunction(a){
    const permission = getCookie("permission-web-payout")
    
    const filter = JSON.parse(permission).filter(x => x === a).toString()

    return filter === a 
}

export function permissionVersion(a){
    const permission = getCookie('version')

    return permission === a
}