import { TableRow } from '@mui/material'
import React from 'react'

const CustomTableRow = ({children, customStyle}) => {
  return (
    <TableRow style={{...customStyle}}>
        {children}
    </TableRow>
  )
}

export default CustomTableRow