import { Redirect, Route } from 'react-router-dom';
import { getCookie } from '../../../utils';

function PublicRoute({ component: Component, ...rest }) {
  const isLogin = getCookie('token-web-payout');
  const version = getCookie('version')
  
  function redirectVersion(){
    if(version === 'full'){
      return '/full/balance'
    } else {
      return '/dashboard'
    }
  }
  return <Route {...rest} render={(props) => (!isLogin ? <Component {...props} /> : <Redirect to={redirectVersion()} />)} />;
}

export default PublicRoute;
