import { Skeleton } from '@mui/material'
import React from 'react'
import { CustomTableCell, CustomTableRow } from '../../Table'

const SkeletonList = ({ col, row, size }) => {

  const calculate = (c, r) => {
    let arr = {
      col: [],
      row: [],
    }

    for (let i = 0; i < c; i++) {
      arr.col.push(i)
    }

    for (let i = 0; i < r; i++) {
      arr.row.push(i)
    }

    return arr
  }


  return (
    <>
      {calculate(col, row).col.map(v => (
        <CustomTableRow key={v}>
          {calculate(col, row).row.map(v => (
            <CustomTableCell key={v}>
              <div style={{paddingLeft : v === 0 ? "0.5rem" : ""}}>
                <Skeleton animation="wave" variant="rectangular" height={size ? size : 30} />
              </div>
            </CustomTableCell>
          ))
          }
        </CustomTableRow>
      ))
      }
    </>
  )
}

export default SkeletonList