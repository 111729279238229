import update from 'immutability-helper';
import { GET_DASHBOARD_FAILED, GET_DASHBOARD_START, GET_DASHBOARD_SUCCESS } from '../actions/actionType';

const initialState = {
    data: {
        frequency_status: [],
        amount_status: [],
        frequency_time: [],
        amount_time: []
    },
    isLoading: false,
    message: ''
}


function dashboardReducer(state = initialState, action) {
    switch (action.type) {
        case GET_DASHBOARD_START:
            return update(state, {
                isLoading: { $set: true },
                data: {
                    frequency_status: { $set: [] },
                    amount_status: { $set: [] },
                    frequency_time: { $set: [] },
                    amount_time: { $set: [] }
                }
            })
        case GET_DASHBOARD_SUCCESS:
            return update(state, {
                isLoading: { $set: false },
                data: {
                    frequency_status: { $set: action.frequency_status },
                    amount_status: { $set: action.amount_status },
                    frequency_time: { $set: action.frequency_time },
                    amount_time: { $set: action.amount_time }
                }
            })
        case GET_DASHBOARD_FAILED:
            return update(state, {
                isLoading: { $set: false },
                message: { $set: action.payload }
            })
        default:
            return state
    }
}

export default dashboardReducer