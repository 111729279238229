import { combineReducers } from 'redux';
import profileReducer from './profileReducer';
import onBoardingReducer from './onBoardingReducer';
import keyReducer from './keyReducer';
import userReducer from './userReducer';
import ipWhitelistReducer from './ipWhitelistReducer';
import qoinviewReducer from './qoinviewReducer';
import merchantReducer from './merchantReducer';
import withdrawalReducer from './withdrawalReducer';
import recipientsReducer from './recipientsReducer';
import layoutReducer from './layoutReducer';
import beneficiaryReducer from './beneficiaryReducer';
import payoutReducer from './payout';
import groupReducer from './groupReducer';
import transactionReducer from './transactionReducer';
import reportReducer from './reportReducer';
import accountReducer from './accountReducer';
import balanceReducer from './balanceReducer';
import settingReducer from './settingReducer';
import createOrderReducer from './createOrderReducer';
import messageReducer from './messageReducer';
import dashboardReducer from './dashboardReducer';

const appReducer = combineReducers({
  profile: profileReducer,
  onboarding: onBoardingReducer,
  key: keyReducer,
  user: userReducer,
  ipWhitelist: ipWhitelistReducer,
  qoinview: qoinviewReducer,
  merchant: merchantReducer,
  withdrawal: withdrawalReducer,
  recipients: recipientsReducer,
  layout: layoutReducer,
  beneficiary: beneficiaryReducer,
  payout: payoutReducer,
  group: groupReducer,
  transaction: transactionReducer,
  report: reportReducer,
  account : accountReducer,
  balance : balanceReducer,
  setting : settingReducer,
  create_order : createOrderReducer,
  notification : messageReducer,
  dashboard : dashboardReducer
});


export default appReducer;
