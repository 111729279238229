

export const setCookie = (key, value) => {
    localStorage.setItem(key, value)
  };
  
  export const removeCookie = (key) => {
    localStorage.removeItem(key)
  };
  
  export const getCookie = (key) => {
    return localStorage.getItem(key)
  };
  
  export const getCookieFromBrowser = (key) => {
    return localStorage.getItem(key)
  };