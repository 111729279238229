import update from 'immutability-helper';
import { GET_LIST_MESSAGE_FAILED, GET_LIST_MESSAGE_START, GET_LIST_MESSAGE_SUCCESS } from '../actions/actionType';

const initiateState = {
    data: {},
    isLoading: false,
    message: ''
}

function messageReducer(state = initiateState, action) {
    switch (action.type) {
        case GET_LIST_MESSAGE_START:
            return update(state, {
                isLoading: { $set: true },
                message: { $set: '' },
            })
        case GET_LIST_MESSAGE_SUCCESS:
            return update(state, {
                isLoading: { $set: false },
                data: { $set: action.payload }
            })
        case GET_LIST_MESSAGE_FAILED:
            return update(state, {
                isLoading : {$set : false},
                message : {$set : action.payload}
            })
        default:
            return state
    }
}

export default messageReducer;