import moment from "moment";


export function timeComparisonIsFuture(a) {

    if (a) {
        const inputDate = moment(a, 'YYYY-MM-DD HH:mm:ss')
        const isFuture = inputDate.isAfter(moment())
        return isFuture
    } else {
        return null
    }
}

export function timeComparisonInHours(a, b = moment()) {

    const messageDate = moment(a);

    const timeDifferenceInHours = b.diff(messageDate, 'hours');

    return timeDifferenceInHours
}