import update from "immutability-helper"
import { POST_TRANSACTION_START, POST_TRANSACTION_SUCCESS,POST_TRANSACTION_FAILED, CLEAR_TRANSACTION_STATE, GENERATE_SCHEDULER_DATE, SET_TRANSACTION_TYPE, GENERATE_RETENTION_BODY } from "../actions/actionType"

const initialState = {
   loadingTransaction : false,
   isSucceed : false,
   isFailed : false,
   messageTransaction : "",
   statusTransaction : "",
   schedulerDate : "",
   retentionBody : {},
   transactionType : 0,
   transactionTypeName : ""
}

function transactionReducer(state = initialState, action){
    switch(action.type){
        case POST_TRANSACTION_START :
            return update(state,{
                loadingTransaction : {$set : true},
                messageTransaction : {$set : ""},
                statusTransaction : {$set : ""},
                isSucceed : {$set : false},
                isFailed : {$set : false}
            })  
        case POST_TRANSACTION_SUCCESS :
            return update(state, {
                loadingTransaction : {$set : false},
                messageTransaction : {$set : action.payload},
                statusTransaction : {$set : "success"},
                isSucceed : {$set : true}
            })
        case POST_TRANSACTION_FAILED : 
            return update(state, {
                loadingTransaction : {$set : false},
                messageTransaction : {$set : action.payload},
                statusTransaction : {$set : "failed"},
                isFailed : {$set : true}
            })
        case GENERATE_SCHEDULER_DATE :
            return update(state, {
                schedulerDate : {$set : action.payload}
            })
        case GENERATE_RETENTION_BODY :
            return update(state, {
                retentionBody : {$set : action.payload}
            })
        case SET_TRANSACTION_TYPE :
            return update(state,{
                transactionType : {$set : action.payload.transferType},
                transactionTypeName : {$set : action.payload.transferTypeName}
            })
        case CLEAR_TRANSACTION_STATE : 
            return update(state, {
                loadingTransaction : {$set : false},
                messageTransaction : {$set : ""},
                statusTransaction : {$set : ""},
                transactionType : {$set : 0},
                transactionTypeName : {$set : ""},
                isSucceed : {$set : false},
                isFailed : {$set : false}
            })
        default :
            return state
    }
}

export default transactionReducer;
