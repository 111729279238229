import update from "immutability-helper"
import { GET_ALL_BALANCE_LIST_CLEAR, GET_ALL_BALANCE_LIST_FAILED, GET_ALL_BALANCE_LIST_START, GET_ALL_BALANCE_LIST_SUCCESS, GET_BALANCE_IN_OUT_FAILED, GET_BALANCE_IN_OUT_START, GET_BALANCE_IN_OUT_SUCCESS, GET_BALANCE_LIST_FAILED, GET_BALANCE_LIST_START, GET_BALANCE_LIST_SUCCESS, GET_MERCHANT_BALANCE_FAILED, GET_MERCHANT_BALANCE_START, GET_MERCHANT_BALANCE_SUCCESS, GET_MERCHANT_VA_FAILED, GET_MERCHANT_VA_START, GET_MERCHANT_VA_SUCCESS } from "../actions/actionType"


const initialState = {
    merchantBalance: {
        data: {},
        isLoading: false,
        message: ""
    },
    balanceInOut: {
        data: {},
        isLoading: false,
        message: ""
    },
    merchantVA : {
        data : {},
        isLoading : false,
        message : ""
    },
    balanceList : {
        data: [],
        isLoading : false,
        message : ""
    },
    allBalanceList : {
        isLoading : false,
        isSucceed : null,
        message : ''
    }
}

function balanceReducer(state = initialState, action) {
    switch (action.type) {
        case GET_MERCHANT_BALANCE_START:
            return update(state, {
                merchantBalance: {
                    isLoading: { $set: true },
                    message: { $set: "" },
                }
            })
        case GET_MERCHANT_BALANCE_SUCCESS:
            return update(state, {
                merchantBalance: {
                    isLoading: { $set: false },
                    data: { $set: action.payload }
                }
            })
        case GET_MERCHANT_BALANCE_FAILED:
            return update(state, {
                merchantBalance: {
                    isLoading: { $set: false },
                    message: { $set: action.payload }
                }
            })
        case GET_BALANCE_IN_OUT_START:
            return update(state, {
                balanceInOut: {
                    isLoading: { $set: true },
                    message: { $set: "" },
                    data: { $set: {} }
                }
            })
        case GET_BALANCE_IN_OUT_SUCCESS:
            return update(state, {
                balanceInOut: {
                    isLoading: { $set: false },
                    data: { $set: action.payload }
                }
            })
        case GET_BALANCE_IN_OUT_FAILED:
            return update(state, {
                balanceInOut: {
                    isLoading: { $set: false },
                    message: { $set: action.payload }
                }
            })
        case GET_MERCHANT_VA_START :
            return update(state, {
                merchantVA : {
                    isLoading : {$set : true},
                    message : {$set:  ""},
                    data : {$set : {}}
                }
            })
        case GET_MERCHANT_VA_SUCCESS :
            return update(state, {
                merchantVA : {
                    data : {$set : action.payload},
                    isLoading : {$set : false}
                }
            })
        case GET_MERCHANT_VA_FAILED :
            return update(state, {
                merchantVA : {
                    message : {$set : action.payload},
                    isLoading : {$set : false}
                }
            })
        case GET_BALANCE_LIST_START :
            return update(state, {
                balanceList : {
                    isLoading : {$set : true},
                    message : {$set : ""},
                }
            })
        case GET_BALANCE_LIST_SUCCESS :
            return update(state, {
                balanceList : {
                    isLoading : {$set : false},
                    data : {$set : action.payload}
                }
            })
        case GET_BALANCE_LIST_FAILED :
            return update(state, {
                balanceList : {
                    isLoading : {$set : false},
                    message : {$set : action.payload}
                }
            })
        case GET_ALL_BALANCE_LIST_START :
            return update(state, {
                allBalanceList : {
                    isLoading : {$set : true},
                    isSucceed : {$set : null}
                }
            })
        case GET_ALL_BALANCE_LIST_SUCCESS :
            return update(state, {
                allBalanceList : {
                    isLoading : {$set : false},
                    isSucceed : {$set : true}
                }
            })
        case GET_ALL_BALANCE_LIST_FAILED : 
            return update(state, {
                allBalanceList : {
                    isLoading : {$set : false},
                    message : {$set : action.payload}
                }
            })
        case GET_ALL_BALANCE_LIST_CLEAR : 
            return update(state, {
                allBalanceList : {
                    isLoading : {$set : false},
                    message : {$set : ''},
                    isSucceed : {$set : null}
                }
            })
        default:
            return state
    }
}


export default balanceReducer