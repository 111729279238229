import update from "immutability-helper";
import { GET_GROUP_LIST_FAILED, GET_GROUP_LIST_START, GET_GROUP_LIST_SUCCESS, DELETE_GROUP_START, DELETE_GROUP_SUCCESS, DELETE_GROUP_FAILED, EDIT_GROUP_START, EDIT_GROUP_SUCCESS, EDIT_GROUP_FAILED, ADD_NEW_GROUP_SUCCESS, ADD_NEW_GROUP_FAILED, ADD_NEW_GROUP_START } from "../actions/actionType";


const initialState = {
    groupList : {},
    groupListMessage : "",
    groupListLoading : false,
    deleteGroupLoading : false,
    deleteGroupMessage : "",
    deleteGroupStatus : "",
    editGroupLoading : false,
    editGroupMessage : "",
    editGroupStatus : "",
    addNewGroupLoading : false,
    addNewGroupMessage : "",
    addNewGroupStatus : ""
}

function groupReducer(state = initialState, action){
    switch(action.type){
        case GET_GROUP_LIST_START :
            return update(state, {
                groupListLoading : {$set : true}
            })
        case GET_GROUP_LIST_SUCCESS : 
            return update(state, {
                groupListLoading : {$set : false},
                groupList : {$set : action.payload}
            })
        case GET_GROUP_LIST_FAILED : 
            return update(state, {
                groupListLoading : {$set : false},
                groupListMessage : {$set : action.payload}
            })
        case DELETE_GROUP_START : 
            return update(state, {
                deleteGroupLoading : {$set : true},
                deleteGroupMessage : {$set : ""},
                deleteGroupStatus : {$set : ""}
            })
        case DELETE_GROUP_SUCCESS : 
            return update(state, {
                deleteGroupLoading : {$set : false},
                deleteGroupMessage : {$set : action.payload},
                deleteGroupStatus : {$set : "success"}
            })
        case DELETE_GROUP_FAILED :
            return update(state, {
                deleteGroupLoading : {$set : false},
                deleteGroupMessage : {$set : action.payload},
                deleteGroupStatus : {$set : "failed"}
            })
        case EDIT_GROUP_START :
            return update(state, {
                editGroupLoading : {$set : false},
                editGroupMessage : {$set : ""},
                editGroupStatus : {$set : ""}
            })
        case EDIT_GROUP_SUCCESS :
            return update(state,{
                editGroupLoading : {$set : false},
                deleteGroupMessage : {$set : action.payload},
                editGroupStatus : {$set : "success"}
            })
        case EDIT_GROUP_FAILED : 
            return update(state, {
                editGroupLoading : {$set : false},
                editGroupMessage : {$set : action.payload},
                editGroupStatus : {$set : "failed"}
            })
        case ADD_NEW_GROUP_START :
            return update(state, {
                addNewGroupLoading : {$set : true},
                addNewGroupMessage : {$set : ""},
                addNewGroupStatus : {$set : ""}
            })
        case ADD_NEW_GROUP_SUCCESS : 
            return update(state, {
                addNewGroupLoading : {$set : false},
                addNewGroupMessage : {$set : action.payload},
                addNewGroupStatus : {$set : "success"}
            })
        case ADD_NEW_GROUP_FAILED : 
            return update(state, {
                addNewGroupLoading : {$set : false},
                addNewGroupMessage : {$set : action.payload},
                addNewGroupStatus : {$set : "failed"}
            })
        default : 
            return state
    }
}

export default groupReducer;