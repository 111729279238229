import { Box, Table } from '@mui/material';
import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles({
  tableContainer: {
    overflow: 'auto',
    maxWidth: '100%',
    margin: '1rem 0',
  },
  table: {
    fontFamily: 'Inter',
    textTransform: 'none',
  },
});

const BasicTable = ({ children }) => {
  const classes = useStyle();

  return (
    <Box className={classes.tableContainer}>
      <Table className={classes.table}>{children}</Table>
    </Box>
  );
};

export default BasicTable;
