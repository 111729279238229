import update from "immutability-helper"
import { GET_ALL_REPORT_CLEAR, GET_ALL_REPORT_FAILED, GET_ALL_REPORT_START, GET_ALL_REPORT_SUCCESS, GET_DETAIL_REPORT_INSTANT_FAILED, GET_DETAIL_REPORT_INSTANT_START, GET_DETAIL_REPORT_INSTANT_SUCCESS, GET_DETAIL_REPORT_RETENTION_FAILED, GET_DETAIL_REPORT_RETENTION_START, GET_DETAIL_REPORT_RETENTION_SUCCESS, GET_DETAIL_REPORT_SCHEDULER_FAILED, GET_DETAIL_REPORT_SCHEDULER_START, GET_DETAIL_REPORT_SCHEDULER_SUCCESS, GET_REPORT_INSTANT_FAILED, GET_REPORT_INSTANT_START, GET_REPORT_INSTANT_SUCCESS, GET_REPORT_RETENTION_FAILED, GET_REPORT_RETENTION_START, GET_REPORT_RETENTION_SUCCESS, GET_REPORT_SCHEDULER_FAILED, GET_REPORT_SCHEDULER_START, GET_REPORT_SCHEDULER_SUCCESS } from "../actions/actionType"


const initialState = {
    reportInstant: {
        data: [],
        isLoading: false,
        message: ""
    },
    detailReportInstant: {
        data: [],
        isLoading: false,
        message: ""
    },
    reportScheduler: {
        data: [],
        isLoading: false,
        message: ""
    },
    detailReportScheduler: {
        data: [],
        isLoading: false,
        message: ""
    },
    reportRetention: {
        data: [],
        isLoading: false,
        message: ""
    },
    detailReportRetention: {
        data: [],
        isLoading: false,
        message: ""
    },
    getAllReport: {
        isLoading: false,
        isSucceed: null,
        message: ''
    }
}


function reportReducer(state = initialState, action) {
    switch (action.type) {
        case GET_REPORT_INSTANT_START:
            return update(state, {
                reportInstant: {
                    isLoading: { $set: true },
                    message: { $set: "" },
                    data: { $set: [] }
                }
            })
        case GET_REPORT_INSTANT_SUCCESS:
            return update(state, {
                reportInstant: {
                    isLoading: { $set: false },
                    data: { $set: action.payload }
                }
            })
        case GET_REPORT_INSTANT_FAILED:
            return update(state, {
                reportInstant: {
                    isLoading: { $set: false },
                    message: { $set: action.payload }
                }
            })
        case GET_DETAIL_REPORT_INSTANT_START:
            return update(state, {
                detailReportInstant: {
                    isLoading: { $set: true },
                    data: { $set: [] },
                    message: { $set: "" }
                }
            })
        case GET_DETAIL_REPORT_INSTANT_SUCCESS:
            return update(state, {
                detailReportInstant: {
                    isLoading: { $set: false },
                    data: { $set: action.payload }
                }
            })
        case GET_DETAIL_REPORT_INSTANT_FAILED:
            return update(state, {
                detailReportInstant: {
                    isLoading: { $set: false },
                    message: { $set: action.payload }
                }
            })
        case GET_REPORT_SCHEDULER_START:
            return update(state, {
                reportScheduler: {
                    isLoading: { $set: true },
                    data: { $set: [] },
                    message: { $set: "" }
                }
            })
        case GET_REPORT_SCHEDULER_SUCCESS:
            return update(state, {
                reportScheduler: {
                    isLoading: { $set: false },
                    data: { $set: action.payload }
                }
            })
        case GET_REPORT_SCHEDULER_FAILED:
            return update(state, {
                reportScheduler: {
                    isLoading: { $set: false },
                    message: { $set: action.payload }
                }
            })
        case GET_DETAIL_REPORT_SCHEDULER_START:
            return update(state, {
                detailReportScheduler: {
                    isLoading: { $set: true },
                    data: { $set: [] },
                    message: { $set: "" }
                }
            })
        case GET_DETAIL_REPORT_SCHEDULER_SUCCESS:
            return update(state, {
                detailReportScheduler: {
                    isLoading: { $set: false },
                    data: { $set: action.payload }
                }
            })
        case GET_DETAIL_REPORT_SCHEDULER_FAILED:
            return update(state, {
                detailReportScheduler: {
                    isLoading: { $set: false },
                    message: { $set: action.payload }
                }
            })
        case GET_REPORT_RETENTION_START:
            return update(state, {
                reportRetention: {
                    isLoading: { $set: true },
                    message: { $set: "" },
                    data: { $set: [] }
                }
            })
        case GET_REPORT_RETENTION_SUCCESS:
            return update(state, {
                reportRetention: {
                    isLoading: { $set: false },
                    data: { $set: action.payload },
                }
            })
        case GET_REPORT_RETENTION_FAILED:
            return update(state, {
                reportRetention: {
                    isLoading: { $set: false },
                    message: { $set: action.payload }
                }
            })
        case GET_DETAIL_REPORT_RETENTION_START:
            return update(state, {
                detailReportRetention: {
                    isLoading: { $set: true },
                    data: { $set: [] },
                    message: { $set: "" }
                }
            })
        case GET_DETAIL_REPORT_RETENTION_SUCCESS:
            return update(state, {
                detailReportRetention: {
                    isLoading: { $set: false },
                    data: { $set: action.payload }
                }
            })
        case GET_DETAIL_REPORT_RETENTION_FAILED:
            return update(state, {
                detailReportRetention: {
                    isLoading: { $set: false },
                    message: { $set: action.payload }
                }
            })
        case GET_ALL_REPORT_START:
            return update(state, {
                getAllReport: {
                    isLoading: { $set: true },
                    message: { $set: '' }
                }
            })
        case GET_ALL_REPORT_SUCCESS:
            return update(state, {
                getAllReport: {
                    isLoading: { $set: false },
                    isSucceed: { $set: true }
                }
            })
        case GET_ALL_REPORT_FAILED:
            return update(state, {
                isLoading : {$set : false},
                message : {$set : action.payload}
            })
        case GET_ALL_REPORT_CLEAR:
            return update(state, {
                isLoading : {$set : false},
                isSucceed : {$set : null}
            })
        default:
            return state
    }
}

export default reportReducer