export const GetStatusBenef = (status) => {
    let statusTag;
  
    switch (status) {
      case true:
        statusTag = <span className='valid-status'>Valid</span>;
        break;
      case false:
        statusTag = <span className='invalid-status'>invalid</span>;
        break;
      default:
        statusTag = <span className='invalid-status'>{status}</span>;
        break;
    }
  
    return <div className='d-flex justify-content-center'>{statusTag}</div>;
  };