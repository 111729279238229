import React from 'react';
import CustomTableRow from '../Table_Row/Component';
import CustomTableCell from '../Table_Cell/Component';

const TableNoData = () => {
  return (
    <CustomTableRow>
      <CustomTableCell colSpan='9' style={{ textAlign: 'center' }}>
        No Data
      </CustomTableCell>
    </CustomTableRow>
  );
};

export default TableNoData;
