import { Redirect, Route } from 'react-router-dom';
import { getCookie } from '../../../utils';


function PrivateRoute({ component: Component, ...rest }) {
  const isLogin = getCookie('token-web-payout');
  const version = getCookie('version')

  function redirectVersion(){
    if(version === 'full'){
      return '/full/balance'
    } else {
      return '/dashboard'
    }
  }

  return (
        <Route {...rest} render={(props) => (isLogin ? <Component {...props} /> : <Redirect from='/' to={redirectVersion()} />)} />
  );
}

export default PrivateRoute;
