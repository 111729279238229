export const GetStatusReport = (status) => {
    let statusTagValid;
    let statusTagPending;
    let statusTagInvalid;
  
    let setstatusTag = (status,id,className) => {
  
  
      let result = <div style={{cursor: 'default'}} className='mr-2 text-nowrap'>
                      <span className={className} style={{textTransform : "capitalize"}}>{status}</span>
                    </div>
      
      switch (status) {
        case 'success':
          statusTagValid = result
          break;
        case 'pending':
          statusTagPending = result
          break;
        case 'failed':
          statusTagInvalid = result
          break;
      
        default:
          break;
      }
    }
  
  
    if (status !== undefined) {
        if (status === "success") {
          setstatusTag('success','succes','valid-status')
        } else if(status === "pending") {
          setstatusTag('pending','pending','pending-status')
        } else {
          setstatusTag('failed','failed','invalid-status')  
        }
  
    } 
  
    return <div className='d-flex justify-content-center'>{statusTagValid}{statusTagPending}{statusTagInvalid}</div>;
  }