
// import { initialState } from '../initialState';
const initialState = {
  recipients: [
    {
      no: 1,
      noRekening: 98932727383,
      namaRekening: 'BCA',
      namaPemilik: 'John Doe',
      email: 'johndoe@mail.com',
      totalAmount: 700000,
      notes: 'Uang Bulanan',
      status: 'Invalid Name',
    },
  ],
};

function index(state = initialState, action) {
  switch (action.type) {
    case 'ADD_RECIPIENT':
      return {
        ...state,
        recipients: [...state.recipients, action.data],
      };
    case 'DELETE_RECIPIENT':
      return {
        ...state,
        recipients: [action.data],
      };
    default:
      return state;
  }
}

export default index;
