import { TableCell, Typography } from '@mui/material';
import React from 'react';

const CustomTableCell = ({ children, align, colSpan, style, props, noWrap }) => {
  return (
    <>
      {children === 'STATUS' ? (
        <TableCell align='center' style={style} colSpan={colSpan} sx={{ maxWidth: '200px' }}>
          <Typography {...props}>{children}</Typography>
        </TableCell>
      ) : (
        <TableCell align={align ? align : 'left'} style={style} colSpan={colSpan} sx={{ maxWidth: '200px' }}>
          <Typography noWrap={noWrap ? false : true}>{children}</Typography>
        </TableCell>
      )}
    </>
  );
};

export default CustomTableCell;
