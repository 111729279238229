import React from 'react';
import { TableCell, TableHead, TableRow, ThemeProvider, Typography, createTheme } from '@mui/material';
import CustomTableCell from '../Table_Cell/Component';

const theme = createTheme({
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#fef4e2',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: 700,
          fontFamily: 'Manrope',
          padding: '0.5rem 0.5rem',
        },
      },
    },
  },
});

const CustomTableHead = ({ header, hideAction, hideNo, children }) => {
  return (
    <ThemeProvider theme={theme}>
      <TableHead>
        {header.length > 0 ? (
          <TableRow sx={{ height: 50 }}>
            {!hideNo ? (
              <TableCell align='center' style={{ minWidth: 2 }}>
                <Typography>NO</Typography>
              </TableCell>
            ) : (
              <></>
            )}
            {header &&
              header?.length > 0 &&
              header.map((data, index) => (
                <CustomTableCell align='left' sx={{ maxWidth: '200px' }} key={index}>
                  {data.label.toUpperCase()}
                </CustomTableCell>
              ))}
            {!hideAction ? <CustomTableCell>ACTION</CustomTableCell> : <></>}
          </TableRow>
        ) : (
          <>{children}</>
        )}
      </TableHead>
    </ThemeProvider>
  );
};

export default CustomTableHead;
